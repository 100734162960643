import { CommonModule } from '@angular/common';
import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '@app/core/service/authentication.service';
import { FocusDirective as FocusDirective_1 } from '@app/shared/directives/focus.directive';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'nemo-qr',
  standalone: true,
  imports: [FormsModule,ReactiveFormsModule,CommonModule,SharedModule,RouterModule, FocusDirective_1],
  templateUrl: './qr.component.html',
  styleUrl: './qr.component.scss'
})
export class QrComponent implements OnInit,AfterViewChecked {

  @ViewChild(FocusDirective_1, { static: false }) input: FocusDirective_1;
  /**
   *
   */
  constructor(private readonly formBuilder: FormBuilder, public readonly router: Router, private authenticationService: AuthenticationService) {
  
    
  }
  ngAfterViewChecked() {
    if (this.input) {
      this.input.setFocus();
    }
  }
  userForm: FormGroup;
  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      code: ['', [Validators.required]]
    });
  }
  sumbitCode(){
    if(this.authenticationService.getCurrentUserProfile().isAuthenticated){
      this.router.navigate(['parcours','detail',this.userForm.controls['code'].value]);
    } else {
      this.router.navigate(['parcours','libre',this.userForm.controls['code'].value]);
    }
    
  }
}
